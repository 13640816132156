@import "../../assets/scss/Variables.scss";
.sidebar-navigation {
  background-color: $white;
  z-index: 1000;
  left: 0;
  position: fixed;
  bottom: 0;
  top: 60px;
  right: auto;
  overflow: auto;
  border-right: 1px solid $gray-light;
  transition: width 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  nav {
    ul {
      margin: 0;
      padding: 32px 0;
      li {
        width: 100%;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 14px 24px;
          color: $black;
          &.active,
          &:hover {
            color: $blue-bright;
            background-color: $blue-light;
          }
        }
        svg {
          margin-right: 14px;
          font-size: 14px;
          color: $blue-bright;
        }
      }
    }
  }
  .needhelp {
    padding: 0 24px 24px;
    a,
    p {
      font-size: 0.9rem;
    }
  }
  .sidebar-contact,
  .sidebar-twitter {
    width: 47.5% !important;
  }
}
// top banner CSS
// .top-banner {
//   background-color: #fff6f6;
//   color: #9f3a38;
//   padding: 0.5rem 2rem;
//   & ~ .sidebar-navigation {
//     top: 105px;
//   }
// }
