@font-face {
  font-family: "mailazy";
  src: url("../fonts/mailazy.eot?1vhl19");
  src: url("../fonts/mailazy.eot?1vhl19#iefix") format("embedded-opentype"),
    url("../fonts/mailazy.ttf?1vhl19") format("truetype"), url("../fonts/mailazy.woff?1vhl19") format("woff"),
    url("../fonts/mailazy.svg?1vhl19#mailazy") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ml-icon-"],
[class*=" ml-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "mailazy" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ml-icon-alert-circle:before {
  content: "\e900";
}
.ml-icon-alert-triangle:before {
  content: "\e902";
}
.ml-icon-info:before {
  content: "\e901";
}
